import React from "react";
import { useState } from "react";

import ResultTable from "../components/meddra_approval_report/resultsTable";
import { CSVDownloadButton } from "components/helpers";
import { Button } from "react-bootstrap";
import { getCsrfToken } from "../services/csrf_helper";

const MeddraApprovalsReportPage = ({ studyId, data }) => {
  const buildCSVData = () => {
    return data.map((form) => {
      return [
        form.raw_term,
        form.llt_term,
        form.pt_term,
        form.soc_term,
        form.exact_match,
        form.spacing_variation,
        form.pluralisation,
        form.approval_status,
        form.created_by,
        form.created_at,
      ];
    });
  };

  const csvData = buildCSVData();

  const [approvedCombinations, setApprovedCombinations] = useState([]);
  const [inappropriateCombinations, setInappropriateCombinations] = useState(
    []
  );

  const filteredData = data.filter((datum) => {
    return !datum.approval_status;
  });

  const selectApprovedForm = (identifier, value) => {
    selectForm(
      identifier,
      value,
      approvedCombinations,
      setApprovedCombinations
    );
    if (value) {
      selectForm(
        identifier,
        false,
        inappropriateCombinations,
        setInappropriateCombinations
      );
    }
  };

  const selectInappropriateForm = (identifier, value) => {
    selectForm(
      identifier,
      value,
      inappropriateCombinations,
      setInappropriateCombinations
    );
    if (value) {
      selectForm(
        identifier,
        false,
        approvedCombinations,
        setApprovedCombinations
      );
    }
  };

  const selectForm = (identifier, value, state, setter) => {
    const identifierInArray = state.includes(identifier);

    if (identifierInArray && value == false) {
      const newSelectedForms = state.filter((item) => {
        return item != identifier;
      });
      setter(newSelectedForms);
    }

    if (!identifierInArray && value == true) {
      const newSelectedForms = [...state, identifier];
      setter(newSelectedForms);
    }
  };

  const csrfToken = getCsrfToken();

  const buildForm = () => {
    const postLocation = `/studies/${studyId}/reports/meddra_approvals/submit_terms`;

    const buildApprovedInputs = () => {
      return approvedCombinations.map((form) => {
        return (
          <input
            type="hidden"
            name="approved_forms[]"
            value={form}
            key={form}
          ></input>
        );
      });
    };

    const buildInappropriateInputs = () => {
      return inappropriateCombinations.map((form) => {
        return (
          <input
            type="hidden"
            name="inappropriate_forms[]"
            value={form}
            key={form}
          ></input>
        );
      });
    };

    return (
      <form
        action={postLocation}
        method="post"
        className="mb-2 d-flex justify-content-end"
      >
        <input
          type="hidden"
          name="authenticity_token"
          value={csrfToken || ""}
        />
        {buildApprovedInputs()}
        {buildInappropriateInputs()}
        <Button
          variant="primary"
          type="submit"
          disabled={
            approvedCombinations.length == 0 &&
            inappropriateCombinations.length == 0
          }
          data-confirm="This cannot be undone, are you sure you would like to approve the selected forms"
        >
          Submit Selected Forms
        </Button>
      </form>
    );
  };

  return (
    <>
      {csvData.length > 0 && (
        <div className="mb-4">
          <CSVDownloadButton
            headers={[
              "Raw Term",
              "LLT",
              "PT",
              "SOC",
              "Exact Match?",
              "Spacing Variation",
              "Pluralisation",
              "Status",
              "Created By",
              "Approved/Inappropriate At",
            ]}
            data={csvData}
            filename={`${studyId}_meddra_approvals_report`}
            addTimeStamp={true}
            active={csvData.length > 0}
          >
            Download results for {csvData.length} form
            {csvData.length == 1 ? null : "s"}
          </CSVDownloadButton>
        </div>
      )}
      {filteredData.length == 0 ? (
        <div className="text-muted">
          There are no available combinations to review.
        </div>
      ) : (
        <>
          {buildForm()}
          <ResultTable
            forms={filteredData}
            approvedCombinations={approvedCombinations}
            inappropriateCombinations={inappropriateCombinations}
            selectApprovedForm={selectApprovedForm}
            selectInappropriateForm={selectInappropriateForm}
          ></ResultTable>
        </>
      )}
    </>
  );
};

export default MeddraApprovalsReportPage;
