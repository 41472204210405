import React from "react";

import { Table, Form } from "react-bootstrap";

const ResultTable = ({
  forms,
  approvedCombinations,
  inappropriateCombinations,
  selectApprovedForm,
  selectInappropriateForm,
}) => {
  const renderedRows = forms.map((form, index) => {
    // We use "£££" as a seperator as we wanted something that the user is unlikely to enter into raw_term
    const identifier = `${form.raw_term}£££${form.llt_term}£££${form.llt_code}£££${form.pt_code}£££${form.soc_code}`;
    const approvedChecked = approvedCombinations.includes(identifier);
    const inappropriateChecked = inappropriateCombinations.includes(identifier);
    return (
      <tr key={index} id={`row-${index}`}>
        <td>{form.raw_term}</td>
        <td>{form.llt_term}</td>
        <td>{form.pt_term}</td>
        <td>{form.soc_term}</td>
        <td id={`row-${index}-exact`}>{form.exact_match ? "Yes" : "No"}</td>
        <td id={`row-${index}-spacing`}>
          {form.spacing_variation ? "Yes" : "No"}
        </td>
        <td id={`row-${index}-pluralisation`}>
          {form.pluralisation ? "Yes" : "No"}
        </td>
        <td>
          <Form.Check
            type="checkbox"
            name="mark_inappropriate"
            checked={inappropriateChecked}
            onChange={(e) => {
              selectInappropriateForm(identifier, e.target.checked);
            }}
          ></Form.Check>
        </td>
        <td>
          <Form.Check
            type="checkbox"
            name="mark_approved"
            checked={approvedChecked}
            onChange={(e) => {
              selectApprovedForm(identifier, e.target.checked);
            }}
          ></Form.Check>
        </td>
      </tr>
    );
  });

  return (
    <Table hover bordered>
      <thead className="thead-light">
        <tr>
          <th className="">AE Raw Term</th>
          <th className="">LLT</th>
          <th className="">PT</th>
          <th className="">SOC</th>
          <th className="">Exact Match?</th>
          <th className="">Spacing Variation</th>
          <th className="">Pluralisation</th>
          <th className="">Inappropriate</th>
          <th className="">Approve</th>
        </tr>
      </thead>
      <tbody>{renderedRows}</tbody>
    </Table>
  );
};

export default ResultTable;
